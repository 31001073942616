import React, { useReducer, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FornituraToBe from "../components/flussi-processi-inself/fornitura-to-be";
import { processiInselfConfiguration, stepCookies } from "../utility/config-flussi-processi-inself";
import SceltaCliente from "../components/flussi-processi-inself/scelta-cliente";
import FornituraAsIs from "../components/flussi-processi-inself/fornitura-as-is";
import { flussoReducer } from "../utility/config-flussi-processi-inself";
import SceltaProcesso from "../components/flussi-processi-inself/scelta-processo";
import SceltaPrezzo from "../components/flussi-processi-inself/scelta-prezzo";
import ClienteTutelato from "../components/flussi-processi-inself/cliente-tutelato";
import SceltaCaldaia from "../components/flussi-processi-inself/scelta-caldaia";
import OutputProcessi from "../components/flussi-processi-inself/output/output-processi";
import Cookies from "js-cookie";

const ConfiguratoreProdottiPage = ({ location }) => {
  const [state, dispatch] = useReducer(flussoReducer, { data: {}, stepFlusso: -1 });
  const { data: flussoData, cookies: flussoCookies, listaStep, stepFlusso } = state;
  const currentStep = listaStep?.[stepFlusso];

  useEffect(() => {
    const fornituraFromEntryPoint = window.sessionStorage.getItem("#step1");
    const flussoConfig = processiInselfConfiguration.find((item) => item.nome === "processiInself");
    const flussoName = "flussoProcessiInself";

    stepFlusso === -1 &&
      dispatch({
        type: "START_FLUSSO",
        payload: {
          flussoName,
          flussoConfig,
          location,
          startStep: location.hash === "#step2" ? parseInt(location.hash.slice(-1)) - 1 : 0,
        },
      });

    fornituraFromEntryPoint !== null &&
      stepFlusso === -1 &&
      dispatch({
        type: "SET_PARAMETER",
        payload: { key: "fornituraToBe", value: fornituraFromEntryPoint },
      });

    fornituraFromEntryPoint !== null &&
      stepFlusso === -1 &&
      dispatch({
        type: "PUSH_ECOMMERCE",
        payload: { fromEntryPoint: true, fornituraValue: fornituraFromEntryPoint },
      });
  }, [dispatch, location, stepFlusso]);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    appHeight();
    window.addEventListener("resize", appHeight);
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    if (flussoData && flussoCookies) {
      Object.entries(flussoCookies)
        .map(([key, value]) => ({
          cookie: key,
          value,
        }))
        .concat(
          Object.entries(flussoData).map(([key, value]) => ({
            cookie: stepCookies[key],
            value: value,
          }))
        )
        .forEach(({ cookie, value }) => {
          if (cookie !== undefined) {
            console.info("Update flusso cookie: ", cookie, value);
            if (value) {
              Cookies.set(cookie, value);
            } else {
              Cookies.remove(cookie);
            }
          }
        });
    }
  }, [flussoData, flussoCookies]);

  return (
    <Layout
      headerType="flusso"
      footerType="bg"
      state={state}
      dispatch={dispatch}
      showChatWidget={false}
    >
      <Seo title="Configuratore prodotti" path="/configuratore-prodotti/" robots="none" />
      <div className="processi-inself__container">
        {currentStep !== "output-processi" && listaStep && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <progress
                  value={
                    state?.data?.schermata === "c" && currentStep === "scelta-processo"
                      ? 100
                      : Math.min(((stepFlusso + 1) / (listaStep?.length - 1)) * 100, 95)
                  }
                  max="100"
                  style={{
                    "--progress": `${
                      state?.data?.schermata === "c" && currentStep === "scelta-processo"
                        ? 100
                        : Math.min(((stepFlusso + 1) / (listaStep?.length - 1)) * 100, 95)
                    }%`,
                  }}
                ></progress>
              </div>
            </div>
          </div>
        )}
        {
          {
            "fornitura-to-be": <FornituraToBe state={state} dispatch={dispatch} />,
            "scelta-cliente": <SceltaCliente state={state} dispatch={dispatch} />,
            "fornitura-as-is": <FornituraAsIs state={state} dispatch={dispatch} />,
            "scelta-processo": <SceltaProcesso state={state} dispatch={dispatch} />,
            "scelta-prezzo": <SceltaPrezzo state={state} dispatch={dispatch} />,
            "cliente-tutelato": <ClienteTutelato state={state} dispatch={dispatch} />,
            "scelta-caldaia": <SceltaCaldaia state={state} dispatch={dispatch} />,
            "output-processi": (
              <OutputProcessi state={state} dispatch={dispatch} location={location} />
            ),
            spinner: (
              <div className="d-flex justify-content-center align-items-center">
                <div className="loader" role="status" aria-hidden="true"></div>
              </div>
            ),
          }[currentStep]
        }
      </div>
    </Layout>
  );
};

export default ConfiguratoreProdottiPage;
